.wrap {
   width: calc(100vmin - var(--padding));
   height: calc(100vmin - var(--padding));
   border-radius: 50%;
   background: #30322e;
   position: relative;
   display: flex;
   align-items: center;
   justify-content: center;
   margin: auto;
   padding: 0;
   background-image: radial-gradient(
      circle,
      #215d53,
      #26554f,
      #2b4d4a,
      #2f4645,
      #323e3e
   );
   box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
   --padding: 40px;
   --rotate: 27.69230769230769deg;
}
.circle {
   display: block;
   position: absolute;
   top: 50%;
   left: 50%;
   width: var(--diameter);
   height: var(--diameter);
   margin: calc(var(--diameter) / -2);
   background: #1e554d;
   border-radius: 50%;
   text-align: center;
   line-height: var(--diameter);
   box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
   --diameter: 15vmin;
   --big-radius: calc(50vmin - var(--diameter) / 2 - var(--padding) / 2 - 5px);
}
.active {
   background: #fff934eb;
}
.done {
   background: #29551e;
   box-shadow: rgb(93 50 50 / 25%) 0px 30px 60px -12px inset,
      rgb(0 0 0 / 30%) 0px 18px 36px -18px inset;
}
.done::after {
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-image: url(../../assets/tick.png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
   z-index: 100;
}
.failed {
   background: #551e1ec4;
   box-shadow: rgb(93 50 50 / 25%) 0px 30px 60px -12px inset,
      rgb(0 0 0 / 74%) 0px 18px 36px -18px inset;
}
.failed::after {
   content: "";
   position: absolute;
   width: 100%;
   height: 100%;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
   background-image: url(../../assets/close.png);
   background-position: center;
   background-repeat: no-repeat;
   background-size: contain;
   z-index: 100;
}
.circle-1 {
   transform: translate(var(--big-radius));
}

.circle-2 {
   transform: rotate(calc(var(--rotate) * 1)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-1)));
}

.circle-3 {
   transform: rotate(calc(var(--rotate) * 2)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-2)));
}
.circle-4 {
   transform: rotate(calc(var(--rotate) * 3)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-3)));
}
.circle-5 {
   transform: rotate(calc(var(--rotate) * 4)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-4)));
}
.circle-6 {
   transform: rotate(calc(var(--rotate) * 5)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-5)));
}
.circle-7 {
   transform: rotate(calc(var(--rotate) * 6)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-6)));
}
.circle-8 {
   transform: rotate(calc(var(--rotate) * 7)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-7)));
}
.circle-9 {
   transform: rotate(calc(var(--rotate) * 8)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-8)));
}
.circle-10 {
   transform: rotate(calc(var(--rotate) * 9)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-9)));
}
.circle-11 {
   transform: rotate(calc(var(--rotate) * 10)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-10)));
}
.circle-12 {
   transform: rotate(calc(var(--rotate) * 11)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-11)));
}
.circle-13 {
   transform: rotate(calc(var(--rotate) * 12)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-12)));
}
.circle-14 {
   transform: rotate(calc(var(--rotate) * 13)) translate(var(--big-radius))
      rotate(calc(var(--rotate) * (-13)));
}

@media (min-width: 700px) {
   .wrap {
      width: calc(700px - var(--padding));
      height: calc(700px - var(--padding));
   }
   .circle {
      --diameter: 90px;
      --big-radius: calc(
         350px - var(--diameter) / 2 - var(--padding) / 2 - 5px
      );
   }
}
