@import url("https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap");

.wrap {
   margin: 5px 0;
   text-align: center;
}

.label {
   color: #fff;
   font-weight: 600;
   font-size: calc(16px + 2vmin);
}

.value {
   font-weight: 600;
   color: #fff;
   font-size: calc(24px + 2vmin);
   font-family: "Fredoka One", cursive;
   color: transparent;
   background-image: linear-gradient(#f7e064, #dc7b5a);
   -webkit-background-clip: text;
   background-clip: text;
}
