@import url(https://fonts.googleapis.com/css?family=Oswald:700);
.wrap {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
}

.logo img {
   max-width: calc(256px + 4vmin);
}

.info {
   width: 100%;
   text-align: center;
   /* background-color: rgba(0, 0, 0, 0.4); */
   color: #fff;
   letter-spacing: 0.08em;
   font-weight: 700;
   font-family: Oswald;
   font-size: calc(1.1rem + 2vh);
   line-height: 2rem;
   margin-top: 2vh;
   padding: calc(2vh + 10px);
   text-transform: uppercase;
}
.buttons {
   margin-top: 3vh;
   display: flex;
   justify-content: space-around;
   max-width: 320px;
   margin-left: auto;
   margin-right: auto;
}
.subTitle {
   font-size: 1.2rem;
   padding-top: 1vh;
}
.subTitle a {
   font-size: 0.95rem;
   text-transform: none;
   letter-spacing: normal;
   vertical-align: top;
}
.bg-red {
   background-color: #c52a28;
   padding: 1%;
   border-radius: 5px;
}
