.App-link {
   color: #61dafb;
}

.msg {
   height: 100vh;
   width: 100%;
   display: flex;
   align-items: center;
   justify-content: center;
   background-color: #fff;
   margin: 0;
}
.landing {
   background-image: url(./assets/header.jpg);
   background-size: cover;
   background-position: center;
}

section {
   box-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
   margin: 5vh 10px;
   display: flex;
   flex-direction: column;
   border-radius: 30px;
   overflow: hidden;
}
section .header {
   height: 33vh;
   background-position: top;
   background-size: cover;
}
section .body {
   padding: 10px;
}

section.contract .header {
   background-image: url(./assets/buy.jpg);
}

section.buy .header {
   background-image: url(./assets/contract.jpg);
}

section.referral .header {
   background-image: url(./assets/referral.jpg);
}
/* 
.contract {
   height: 70vh;
   background-image: url(../public/bg-s2000.jpg);
   clip-path: polygon(0 10vh, 100% 0, 100% 60vh, 0% 100%);
   margin-bottom: -10vh;
   transform: translateY(-10vh);
   padding: calc(10vh + 6vh) 0;
} */

/* .referral {
   background-image: url(../public/footer.jpg);
   clip-path: polygon(0 10vh, 100% 0, 100% 100%, 0% 100%);
   margin-bottom: -10vh;
   transform: translateY(-10vh);
   padding: calc(10vh + 6vh) 0;
} */
.video {
   width: 100%;
   height: calc(100vw * 0.57);
   box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
   border: none;
}

.nos-wrap {
   padding-top: 5px;
   color: #000;
}
.nos {
   font-size: 1.5rem;
}
.presale{
   height: calc(32px + 10vmin);
   width: calc(64px + 20vmin);
   position: fixed;
   bottom: 50px;
   left: 20px;
   border-radius: 10px;
   background-image: url(./assets/presale.jpg);
   background-repeat: no-repeat;
   background-position: top;
   background-size: contain;
   z-index: 1;
   box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.8);
}
.presale .link{
   position: absolute;
   bottom: -34px;
   left: 0;
   height: 40px;
   width: 100%;
   background-color: darkgreen;
   color: #fff;
   z-index: 2;
   border: solid 2px #fff;
   border-radius: 20px;
   padding: 8px 0;
   text-decoration: none;
   font-weight: 600;
   text-align: center;
}

.trading{
   height: calc(32px + 10vmin);
   width: calc(64px + 20vmin);
   position: fixed;
   bottom: calc(84px + 12vmin);
   left: 20px;
   border-radius: 10px;
   background-image: url(./assets/trading.png);
   background-repeat: no-repeat;
   background-position: top;
   background-size: cover;
   z-index: 1;
   box-shadow: 0 0 20px 1px rgba(0, 0, 0, 0.8);
}
.trading a{
   text-decoration: none;
}
.trading p{
   color: rgb(255, 251, 19);
   font-weight: 600;
   font-size: 1rem;
   position: absolute;
   bottom: 0;
   margin-bottom: 0;
   text-shadow: 1px 1px 0px rgb(0, 0, 0);
}
.trading p span{
   font-size: 1.4rem;
   text-shadow: 2px 2px 0px rgb(0, 0, 0);
}
.trading img{
   width: 50%;
   position: absolute;
   transform: translateY(-50%);
}
@media (min-width: 480px) {
   section {
      flex-direction: row-reverse;
      align-items: stretch;
   }
   section .header {
      width: 33vw;
      height: auto;
   }
   section .body {
      width: 77vw;
   }
   .main-wrap {
      flex-direction: row;
      justify-content: center;
   }
   .video {
      max-width: 1265px;
   }
}
