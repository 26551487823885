/* CSS */
.button {
   appearance: none;
   background-color: transparent;
   border: 2px solid #383838;
   border-radius: 15px;
   box-sizing: border-box;
   color: #878787;
   cursor: pointer;
   display: inline-block;
   font-family: Roobert, -apple-system, BlinkMacSystemFont, "Segoe UI",
      Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
      "Segoe UI Symbol";
   font-size: 16px;
   font-weight: 600;
   line-height: normal;
   margin: 2px;
   min-height: 60px;
   min-width: 0;
   outline: none;
   padding: 16px 24px;
   text-align: center;
   text-decoration: none;
   transition: all 300ms cubic-bezier(0.23, 1, 0.32, 1);
   user-select: none;
   -webkit-user-select: none;
   touch-action: manipulation;
   width: 100%;
   will-change: transform;
}

.button:disabled {
   pointer-events: none;
}

.button:hover {
   color: #fff;
   background-color: #1a1a1a;
   box-shadow: rgba(0, 0, 0, 0.25) 0 8px 15px;
   transform: translateY(-2px);
}

.button:active {
   box-shadow: none;
   transform: translateY(0);
}

@media (min-width: 768px) {
}
